.react-web--edwinsandbox__container {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: inherit;
  margin: 0;
}

.react-web--edwinsandbox__container *, .react-web--edwinsandbox__container :before, .react-web--edwinsandbox__container :after {
  box-sizing: border-box;
}

.react-web--edwinsandbox__container hr {
  height: 0;
  color: inherit;
}

.react-web--edwinsandbox__container abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.react-web--edwinsandbox__container b, .react-web--edwinsandbox__container strong {
  font-weight: bolder;
}

.react-web--edwinsandbox__container code, .react-web--edwinsandbox__container kbd, .react-web--edwinsandbox__container samp, .react-web--edwinsandbox__container pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

.react-web--edwinsandbox__container small {
  font-size: 80%;
}

.react-web--edwinsandbox__container sub, .react-web--edwinsandbox__container sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.react-web--edwinsandbox__container sub {
  bottom: -.25em;
}

.react-web--edwinsandbox__container sup {
  top: -.5em;
}

.react-web--edwinsandbox__container table {
  text-indent: 0;
  border-color: inherit;
}

.react-web--edwinsandbox__container button, .react-web--edwinsandbox__container input, .react-web--edwinsandbox__container optgroup, .react-web--edwinsandbox__container select, .react-web--edwinsandbox__container textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

.react-web--edwinsandbox__container button, .react-web--edwinsandbox__container select {
  text-transform: none;
}

.react-web--edwinsandbox__container button, .react-web--edwinsandbox__container [type="button"], .react-web--edwinsandbox__container [type="reset"], .react-web--edwinsandbox__container [type="submit"] {
  -webkit-appearance: button;
}

.react-web--edwinsandbox__container ::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.react-web--edwinsandbox__container :-moz-focusring {
  outline: 1px dotted ButtonText;
}

.react-web--edwinsandbox__container :-moz-ui-invalid {
  box-shadow: none;
}

.react-web--edwinsandbox__container legend {
  padding: 0;
}

.react-web--edwinsandbox__container progress {
  vertical-align: baseline;
}

.react-web--edwinsandbox__container ::-webkit-inner-spin-button, .react-web--edwinsandbox__container ::-webkit-outer-spin-button {
  height: auto;
}

.react-web--edwinsandbox__container [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.react-web--edwinsandbox__container ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.react-web--edwinsandbox__container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.react-web--edwinsandbox__container summary {
  display: list-item;
}

.react-web--edwinsandbox__container blockquote, .react-web--edwinsandbox__container dl, .react-web--edwinsandbox__container dd, .react-web--edwinsandbox__container h1, .react-web--edwinsandbox__container h2, .react-web--edwinsandbox__container h3, .react-web--edwinsandbox__container h4, .react-web--edwinsandbox__container h5, .react-web--edwinsandbox__container h6, .react-web--edwinsandbox__container hr, .react-web--edwinsandbox__container figure, .react-web--edwinsandbox__container p, .react-web--edwinsandbox__container pre {
  margin: 0;
}

.react-web--edwinsandbox__container button {
  background-color: #0000;
  background-image: none;
}

.react-web--edwinsandbox__container fieldset {
  margin: 0;
  padding: 0;
}

.react-web--edwinsandbox__container ol, .react-web--edwinsandbox__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-web--edwinsandbox__container *, .react-web--edwinsandbox__container :before, .react-web--edwinsandbox__container :after {
  box-sizing: border-box;
  border: 0 solid;
}

.react-web--edwinsandbox__container hr {
  border-top-width: 1px;
}

.react-web--edwinsandbox__container img {
  border-style: solid;
}

.react-web--edwinsandbox__container textarea {
  resize: vertical;
}

.react-web--edwinsandbox__container input::placeholder, .react-web--edwinsandbox__container textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.react-web--edwinsandbox__container button, .react-web--edwinsandbox__container [role="button"] {
  cursor: pointer;
}

.react-web--edwinsandbox__container table {
  border-collapse: collapse;
}

.react-web--edwinsandbox__container h1, .react-web--edwinsandbox__container h2, .react-web--edwinsandbox__container h3, .react-web--edwinsandbox__container h4, .react-web--edwinsandbox__container h5, .react-web--edwinsandbox__container h6 {
  font-size: inherit;
  font-weight: inherit;
}

.react-web--edwinsandbox__container a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.react-web--edwinsandbox__container button, .react-web--edwinsandbox__container input, .react-web--edwinsandbox__container optgroup, .react-web--edwinsandbox__container select, .react-web--edwinsandbox__container textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

.react-web--edwinsandbox__container pre, .react-web--edwinsandbox__container code, .react-web--edwinsandbox__container kbd, .react-web--edwinsandbox__container samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.react-web--edwinsandbox__container img, .react-web--edwinsandbox__container svg, .react-web--edwinsandbox__container video, .react-web--edwinsandbox__container canvas, .react-web--edwinsandbox__container audio, .react-web--edwinsandbox__container iframe, .react-web--edwinsandbox__container embed, .react-web--edwinsandbox__container object {
  vertical-align: middle;
  display: block;
}

.react-web--edwinsandbox__container img, .react-web--edwinsandbox__container video {
  max-width: 100%;
  height: auto;
}

.react-web--edwinsandbox__container *, .react-web--edwinsandbox__container :before, .react-web--edwinsandbox__container :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

.form-input::-webkit-datetime-edit, .form-input::-webkit-datetime-edit-year-field, .form-input::-webkit-datetime-edit-month-field, .form-input::-webkit-datetime-edit-day-field, .form-input::-webkit-datetime-edit-hour-field, .form-input::-webkit-datetime-edit-minute-field, .form-input::-webkit-datetime-edit-second-field, .form-input::-webkit-datetime-edit-millisecond-field, .form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.react-web--edwinsandbox__container .pointer-events-none {
  pointer-events: none;
}

.react-web--edwinsandbox__container .pointer-events-auto {
  pointer-events: auto;
}

.react-web--edwinsandbox__container .visible {
  visibility: visible;
}

.react-web--edwinsandbox__container .invisible {
  visibility: hidden;
}

.react-web--edwinsandbox__container .static {
  position: static;
}

.react-web--edwinsandbox__container .fixed {
  position: fixed;
}

.react-web--edwinsandbox__container .absolute {
  position: absolute;
}

.react-web--edwinsandbox__container .relative {
  position: relative;
}

.react-web--edwinsandbox__container .inset-0 {
  inset: 0;
}

.react-web--edwinsandbox__container .inset-x-0 {
  left: 0;
  right: 0;
}

.react-web--edwinsandbox__container .bottom-0 {
  bottom: 0;
}

.react-web--edwinsandbox__container .left-1\/2 {
  left: 50%;
}

.react-web--edwinsandbox__container .left-2 {
  left: .5rem;
}

.react-web--edwinsandbox__container .left-4 {
  left: 1rem;
}

.react-web--edwinsandbox__container .left-\[19px\] {
  left: 19px;
}

.react-web--edwinsandbox__container .right-0 {
  right: 0;
}

.react-web--edwinsandbox__container .right-2 {
  right: .5rem;
}

.react-web--edwinsandbox__container .right-3 {
  right: .75rem;
}

.react-web--edwinsandbox__container .right-4 {
  right: 1rem;
}

.react-web--edwinsandbox__container .top-0 {
  top: 0;
}

.react-web--edwinsandbox__container .top-1\/2 {
  top: 50%;
}

.react-web--edwinsandbox__container .top-2 {
  top: .5rem;
}

.react-web--edwinsandbox__container .top-3 {
  top: .75rem;
}

.react-web--edwinsandbox__container .top-\[40px\] {
  top: 40px;
}

.react-web--edwinsandbox__container .top-\[52px\] {
  top: 52px;
}

.react-web--edwinsandbox__container .z-0 {
  z-index: 0;
}

.react-web--edwinsandbox__container .z-10 {
  z-index: 10;
}

.react-web--edwinsandbox__container .z-40 {
  z-index: 40;
}

.react-web--edwinsandbox__container .z-50 {
  z-index: 50;
}

.react-web--edwinsandbox__container .z-\[10000\] {
  z-index: 10000;
}

.react-web--edwinsandbox__container .m-0 {
  margin: 0;
}

.react-web--edwinsandbox__container .-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.react-web--edwinsandbox__container .-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.react-web--edwinsandbox__container .mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.react-web--edwinsandbox__container .mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.react-web--edwinsandbox__container .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.react-web--edwinsandbox__container .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.react-web--edwinsandbox__container .-mr-1 {
  margin-right: -.25rem;
}

.react-web--edwinsandbox__container .-mr-1\.5 {
  margin-right: -.375rem;
}

.react-web--edwinsandbox__container .-mr-4 {
  margin-right: -1rem;
}

.react-web--edwinsandbox__container .mb-1 {
  margin-bottom: .25rem;
}

.react-web--edwinsandbox__container .mb-1\.5 {
  margin-bottom: .375rem;
}

.react-web--edwinsandbox__container .mb-2 {
  margin-bottom: .5rem;
}

.react-web--edwinsandbox__container .mb-2\.5 {
  margin-bottom: .625rem;
}

.react-web--edwinsandbox__container .mb-3 {
  margin-bottom: .75rem;
}

.react-web--edwinsandbox__container .mb-3\.5 {
  margin-bottom: .875rem;
}

.react-web--edwinsandbox__container .mb-4 {
  margin-bottom: 1rem;
}

.react-web--edwinsandbox__container .mb-5 {
  margin-bottom: 1.25rem;
}

.react-web--edwinsandbox__container .ml-1 {
  margin-left: .25rem;
}

.react-web--edwinsandbox__container .ml-1\.5 {
  margin-left: .375rem;
}

.react-web--edwinsandbox__container .ml-10 {
  margin-left: 2.5rem;
}

.react-web--edwinsandbox__container .ml-2 {
  margin-left: .5rem;
}

.react-web--edwinsandbox__container .ml-3 {
  margin-left: .75rem;
}

.react-web--edwinsandbox__container .ml-5 {
  margin-left: 1.25rem;
}

.react-web--edwinsandbox__container .ml-6 {
  margin-left: 1.5rem;
}

.react-web--edwinsandbox__container .ml-auto {
  margin-left: auto;
}

.react-web--edwinsandbox__container .mr-1 {
  margin-right: .25rem;
}

.react-web--edwinsandbox__container .mr-10 {
  margin-right: 2.5rem;
}

.react-web--edwinsandbox__container .mr-2 {
  margin-right: .5rem;
}

.react-web--edwinsandbox__container .mr-2\.5 {
  margin-right: .625rem;
}

.react-web--edwinsandbox__container .mt-1 {
  margin-top: .25rem;
}

.react-web--edwinsandbox__container .mt-1\.5 {
  margin-top: .375rem;
}

.react-web--edwinsandbox__container .mt-10 {
  margin-top: 2.5rem;
}

.react-web--edwinsandbox__container .mt-2 {
  margin-top: .5rem;
}

.react-web--edwinsandbox__container .mt-2\.5 {
  margin-top: .625rem;
}

.react-web--edwinsandbox__container .mt-3 {
  margin-top: .75rem;
}

.react-web--edwinsandbox__container .mt-4 {
  margin-top: 1rem;
}

.react-web--edwinsandbox__container .mt-5 {
  margin-top: 1.25rem;
}

.react-web--edwinsandbox__container .mt-6 {
  margin-top: 1.5rem;
}

.react-web--edwinsandbox__container .mt-8 {
  margin-top: 2rem;
}

.react-web--edwinsandbox__container .mt-px {
  margin-top: 1px;
}

.react-web--edwinsandbox__container .line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.react-web--edwinsandbox__container .block {
  display: block;
}

.react-web--edwinsandbox__container .inline-block {
  display: inline-block;
}

.react-web--edwinsandbox__container .inline {
  display: inline;
}

.react-web--edwinsandbox__container .flex {
  display: flex;
}

.react-web--edwinsandbox__container .inline-flex {
  display: inline-flex;
}

.react-web--edwinsandbox__container .grid {
  display: grid;
}

.react-web--edwinsandbox__container .hidden {
  display: none;
}

.react-web--edwinsandbox__container .h-1 {
  height: .25rem;
}

.react-web--edwinsandbox__container .h-10 {
  height: 2.5rem;
}

.react-web--edwinsandbox__container .h-16 {
  height: 4rem;
}

.react-web--edwinsandbox__container .h-24 {
  height: 6rem;
}

.react-web--edwinsandbox__container .h-4 {
  height: 1rem;
}

.react-web--edwinsandbox__container .h-5 {
  height: 1.25rem;
}

.react-web--edwinsandbox__container .h-6 {
  height: 1.5rem;
}

.react-web--edwinsandbox__container .h-7 {
  height: 1.75rem;
}

.react-web--edwinsandbox__container .h-72 {
  height: 18rem;
}

.react-web--edwinsandbox__container .h-8 {
  height: 2rem;
}

.react-web--edwinsandbox__container .h-\[351px\] {
  height: 351px;
}

.react-web--edwinsandbox__container .h-\[360px\] {
  height: 360px;
}

.react-web--edwinsandbox__container .h-\[36px\] {
  height: 36px;
}

.react-web--edwinsandbox__container .h-\[410px\] {
  height: 410px;
}

.react-web--edwinsandbox__container .h-auto {
  height: auto;
}

.react-web--edwinsandbox__container .h-full {
  height: 100%;
}

.react-web--edwinsandbox__container .h-px {
  height: 1px;
}

.react-web--edwinsandbox__container .h-screen {
  height: 100vh;
}

.react-web--edwinsandbox__container .max-h-72 {
  max-height: 18rem;
}

.react-web--edwinsandbox__container .max-h-\[300px\] {
  max-height: 300px;
}

.react-web--edwinsandbox__container .max-h-\[90vh\] {
  max-height: 90vh;
}

.react-web--edwinsandbox__container .min-h-\[120px\] {
  min-height: 120px;
}

.react-web--edwinsandbox__container .min-h-\[37px\] {
  min-height: 37px;
}

.react-web--edwinsandbox__container .min-h-\[667px\] {
  min-height: 667px;
}

.react-web--edwinsandbox__container .min-h-full {
  min-height: 100%;
}

.react-web--edwinsandbox__container .min-h-screen {
  min-height: 100vh;
}

.react-web--edwinsandbox__container .w-1 {
  width: .25rem;
}

.react-web--edwinsandbox__container .w-10 {
  width: 2.5rem;
}

.react-web--edwinsandbox__container .w-12 {
  width: 3rem;
}

.react-web--edwinsandbox__container .w-14 {
  width: 3.5rem;
}

.react-web--edwinsandbox__container .w-16 {
  width: 4rem;
}

.react-web--edwinsandbox__container .w-32 {
  width: 8rem;
}

.react-web--edwinsandbox__container .w-36 {
  width: 9rem;
}

.react-web--edwinsandbox__container .w-4 {
  width: 1rem;
}

.react-web--edwinsandbox__container .w-5 {
  width: 1.25rem;
}

.react-web--edwinsandbox__container .w-6 {
  width: 1.5rem;
}

.react-web--edwinsandbox__container .w-64 {
  width: 16rem;
}

.react-web--edwinsandbox__container .w-7 {
  width: 1.75rem;
}

.react-web--edwinsandbox__container .w-8 {
  width: 2rem;
}

.react-web--edwinsandbox__container .w-\[276px\] {
  width: 276px;
}

.react-web--edwinsandbox__container .w-\[300px\] {
  width: 300px;
}

.react-web--edwinsandbox__container .w-\[377px\] {
  width: 377px;
}

.react-web--edwinsandbox__container .w-full {
  width: 100%;
}

.react-web--edwinsandbox__container .w-px {
  width: 1px;
}

.react-web--edwinsandbox__container .min-w-\[200px\] {
  min-width: 200px;
}

.react-web--edwinsandbox__container .\!max-w-none {
  max-width: none !important;
}

.react-web--edwinsandbox__container .max-w-2xl {
  max-width: 42rem;
}

.react-web--edwinsandbox__container .max-w-3xl {
  max-width: 48rem;
}

.react-web--edwinsandbox__container .max-w-72 {
  max-width: 18rem;
}

.react-web--edwinsandbox__container .max-w-\[165px\] {
  max-width: 165px;
}

.react-web--edwinsandbox__container .max-w-\[240px\] {
  max-width: 240px;
}

.react-web--edwinsandbox__container .max-w-\[280px\] {
  max-width: 280px;
}

.react-web--edwinsandbox__container .max-w-\[680px\] {
  max-width: 680px;
}

.react-web--edwinsandbox__container .max-w-lg {
  max-width: 32rem;
}

.react-web--edwinsandbox__container .max-w-md {
  max-width: 28rem;
}

.react-web--edwinsandbox__container .max-w-prose {
  max-width: 65ch;
}

.react-web--edwinsandbox__container .flex-1 {
  flex: 1;
}

.react-web--edwinsandbox__container .flex-shrink-0 {
  flex-shrink: 0;
}

.react-web--edwinsandbox__container .flex-grow-0 {
  flex-grow: 0;
}

.react-web--edwinsandbox__container .grow {
  flex-grow: 1;
}

.react-web--edwinsandbox__container .-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--edwinsandbox__container .transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes border-radius {
  0% {
    border-radius: 10px;
  }

  100% {
    border-radius: 10px 0 10px 10px;
  }
}

.react-web--edwinsandbox__container .animate-border-radius {
  animation: .15s forwards border-radius;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.react-web--edwinsandbox__container .animate-fade-in {
  animation: .2s 50ms forwards fade-in;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-web--edwinsandbox__container .animate-fade-in-down {
  animation: .4s ease-out forwards fade-in-down;
}

@keyframes fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.react-web--edwinsandbox__container .animate-fade-out-up {
  animation: .55s ease-out forwards fade-out-up;
}

@keyframes message {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-web--edwinsandbox__container .animate-message {
  animation: .2s 50ms forwards message;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.react-web--edwinsandbox__container .animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes typing-dot {
  0% {
    transform: translateY(0);
  }

  28% {
    transform: translateY(-7px);
  }

  44% {
    transform: translateY(0);
  }
}

.react-web--edwinsandbox__container .animate-typing-dot {
  animation: 1s ease-in-out infinite typing-dot;
}

.react-web--edwinsandbox__container .cursor-auto {
  cursor: auto;
}

.react-web--edwinsandbox__container .cursor-default {
  cursor: default;
}

.react-web--edwinsandbox__container .cursor-grab {
  cursor: grab;
}

.react-web--edwinsandbox__container .cursor-pointer {
  cursor: pointer;
}

.react-web--edwinsandbox__container .cursor-zoom-in {
  cursor: zoom-in;
}

.react-web--edwinsandbox__container .cursor-zoom-out {
  cursor: zoom-out;
}

.react-web--edwinsandbox__container .touch-none {
  touch-action: none;
}

.react-web--edwinsandbox__container .resize {
  resize: both;
}

.react-web--edwinsandbox__container .snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.react-web--edwinsandbox__container .snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.react-web--edwinsandbox__container .snap-center {
  scroll-snap-align: center;
}

.react-web--edwinsandbox__container .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.react-web--edwinsandbox__container .flex-row {
  flex-direction: row;
}

.react-web--edwinsandbox__container .flex-col {
  flex-direction: column;
}

.react-web--edwinsandbox__container .flex-wrap {
  flex-wrap: wrap;
}

.react-web--edwinsandbox__container .flex-nowrap {
  flex-wrap: nowrap;
}

.react-web--edwinsandbox__container .items-start {
  align-items: flex-start;
}

.react-web--edwinsandbox__container .items-end {
  align-items: flex-end;
}

.react-web--edwinsandbox__container .items-center {
  align-items: center;
}

.react-web--edwinsandbox__container .items-baseline {
  align-items: baseline;
}

.react-web--edwinsandbox__container .justify-end {
  justify-content: flex-end;
}

.react-web--edwinsandbox__container .justify-center {
  justify-content: center;
}

.react-web--edwinsandbox__container .justify-between {
  justify-content: space-between;
}

.react-web--edwinsandbox__container .gap-1 {
  gap: .25rem;
}

.react-web--edwinsandbox__container .gap-x-0 {
  column-gap: 0;
}

.react-web--edwinsandbox__container .gap-x-0\.5 {
  column-gap: .125rem;
}

.react-web--edwinsandbox__container .gap-x-1 {
  column-gap: .25rem;
}

.react-web--edwinsandbox__container .gap-x-1\.5 {
  column-gap: .375rem;
}

.react-web--edwinsandbox__container .gap-x-2 {
  column-gap: .5rem;
}

.react-web--edwinsandbox__container .gap-x-2\.5 {
  column-gap: .625rem;
}

.react-web--edwinsandbox__container .gap-y-1 {
  row-gap: .25rem;
}

.react-web--edwinsandbox__container .gap-y-1\.5 {
  row-gap: .375rem;
}

.react-web--edwinsandbox__container .gap-y-2 {
  row-gap: .5rem;
}

.react-web--edwinsandbox__container .gap-y-2\.5 {
  row-gap: .625rem;
}

.react-web--edwinsandbox__container :is(.divide-y-\[1px\] > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.react-web--edwinsandbox__container :is(.divide-gray-200 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.react-web--edwinsandbox__container .self-start {
  align-self: flex-start;
}

.react-web--edwinsandbox__container .self-center {
  align-self: center;
}

.react-web--edwinsandbox__container .overflow-auto {
  overflow: auto;
}

.react-web--edwinsandbox__container .overflow-hidden {
  overflow: hidden;
}

.react-web--edwinsandbox__container .overflow-x-auto {
  overflow-x: auto;
}

.react-web--edwinsandbox__container .overflow-y-auto {
  overflow-y: auto;
}

.react-web--edwinsandbox__container .overflow-x-hidden {
  overflow-x: hidden;
}

.react-web--edwinsandbox__container .scroll-smooth {
  scroll-behavior: smooth;
}

.react-web--edwinsandbox__container .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-web--edwinsandbox__container .whitespace-nowrap {
  white-space: nowrap;
}

.react-web--edwinsandbox__container .\!rounded-lg {
  border-radius: .5rem !important;
}

.react-web--edwinsandbox__container .rounded {
  border-radius: .25rem;
}

.react-web--edwinsandbox__container .rounded-\[10px\] {
  border-radius: 10px;
}

.react-web--edwinsandbox__container .rounded-full {
  border-radius: 9999px;
}

.react-web--edwinsandbox__container .rounded-lg {
  border-radius: .5rem;
}

.react-web--edwinsandbox__container .rounded-md {
  border-radius: .375rem;
}

.react-web--edwinsandbox__container .rounded-sm {
  border-radius: .125rem;
}

.react-web--edwinsandbox__container .\!rounded-l-\[10px\] {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.react-web--edwinsandbox__container .\!rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.react-web--edwinsandbox__container .\!rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.react-web--edwinsandbox__container .\!rounded-r-\[10px\] {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-web--edwinsandbox__container .\!rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.react-web--edwinsandbox__container .rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-web--edwinsandbox__container .rounded-tl-none {
  border-top-left-radius: 0;
}

.react-web--edwinsandbox__container .\!border {
  border-width: 1px !important;
}

.react-web--edwinsandbox__container .border {
  border-width: 1px;
}

.react-web--edwinsandbox__container .border-2 {
  border-width: 2px;
}

.react-web--edwinsandbox__container .border-b {
  border-bottom-width: 1px;
}

.react-web--edwinsandbox__container .border-b-0 {
  border-bottom-width: 0;
}

.react-web--edwinsandbox__container .border-l-0 {
  border-left-width: 0;
}

.react-web--edwinsandbox__container .border-r-0 {
  border-right-width: 0;
}

.react-web--edwinsandbox__container .border-t {
  border-top-width: 1px;
}

.react-web--edwinsandbox__container .\!border-codGray {
  --tw-border-opacity: 1 !important;
  border-color: rgb(25 25 25 / var(--tw-border-opacity)) !important;
}

.react-web--edwinsandbox__container .border-\[\#C4C4C4\] {
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-alto {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-codGray {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-green-dark {
  --tw-border-opacity: 1;
  border-color: rgb(42 57 90 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .bg-\[\#EAEAEA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-alto {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-black\/90 {
  background-color: #000000e6;
}

.react-web--edwinsandbox__container .bg-codGray {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-codGray\/90 {
  background-color: #191919e6;
}

.react-web--edwinsandbox__container .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-gray-50\/60 {
  background-color: #f9fafb99;
}

.react-web--edwinsandbox__container .bg-gray-50\/90 {
  background-color: #f9fafbe6;
}

.react-web--edwinsandbox__container .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-gray-900\/75 {
  background-color: #111827bf;
}

.react-web--edwinsandbox__container .bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(124 145 207 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-green-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(42 57 90 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-transparent {
  background-color: #0000;
}

.react-web--edwinsandbox__container .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-white\/80 {
  background-color: #fffc;
}

.react-web--edwinsandbox__container .bg-wildSand {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.react-web--edwinsandbox__container .bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.react-web--edwinsandbox__container .fill-current {
  fill: currentColor;
}

.react-web--edwinsandbox__container .fill-gray-500 {
  fill: #6b7280;
}

.react-web--edwinsandbox__container .object-cover {
  object-fit: cover;
}

.react-web--edwinsandbox__container .p-0 {
  padding: 0;
}

.react-web--edwinsandbox__container .p-1 {
  padding: .25rem;
}

.react-web--edwinsandbox__container .p-3 {
  padding: .75rem;
}

.react-web--edwinsandbox__container .p-4 {
  padding: 1rem;
}

.react-web--edwinsandbox__container .p-6 {
  padding: 1.5rem;
}

.react-web--edwinsandbox__container .\!px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.react-web--edwinsandbox__container .\!px-2\.5 {
  padding-left: .625rem !important;
  padding-right: .625rem !important;
}

.react-web--edwinsandbox__container .\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.react-web--edwinsandbox__container .\!py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.react-web--edwinsandbox__container .\!py-1\.5 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.react-web--edwinsandbox__container .\!py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.react-web--edwinsandbox__container .px-0 {
  padding-left: 0;
  padding-right: 0;
}

.react-web--edwinsandbox__container .px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.react-web--edwinsandbox__container .px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.react-web--edwinsandbox__container .px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.react-web--edwinsandbox__container .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.react-web--edwinsandbox__container .px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.react-web--edwinsandbox__container .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.react-web--edwinsandbox__container .px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.react-web--edwinsandbox__container .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-web--edwinsandbox__container .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.react-web--edwinsandbox__container .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.react-web--edwinsandbox__container .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.react-web--edwinsandbox__container .py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.react-web--edwinsandbox__container .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.react-web--edwinsandbox__container .py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.react-web--edwinsandbox__container .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.react-web--edwinsandbox__container .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.react-web--edwinsandbox__container .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.react-web--edwinsandbox__container .py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.react-web--edwinsandbox__container .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.react-web--edwinsandbox__container .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.react-web--edwinsandbox__container .pb-0 {
  padding-bottom: 0;
}

.react-web--edwinsandbox__container .pb-0\.5 {
  padding-bottom: .125rem;
}

.react-web--edwinsandbox__container .pb-1 {
  padding-bottom: .25rem;
}

.react-web--edwinsandbox__container .pb-1\.5 {
  padding-bottom: .375rem;
}

.react-web--edwinsandbox__container .pb-2 {
  padding-bottom: .5rem;
}

.react-web--edwinsandbox__container .pb-3 {
  padding-bottom: .75rem;
}

.react-web--edwinsandbox__container .pb-4 {
  padding-bottom: 1rem;
}

.react-web--edwinsandbox__container .pb-5 {
  padding-bottom: 1.25rem;
}

.react-web--edwinsandbox__container .pb-6 {
  padding-bottom: 1.5rem;
}

.react-web--edwinsandbox__container .pr-0 {
  padding-right: 0;
}

.react-web--edwinsandbox__container .pr-0\.5 {
  padding-right: .125rem;
}

.react-web--edwinsandbox__container .pr-3 {
  padding-right: .75rem;
}

.react-web--edwinsandbox__container .pt-0 {
  padding-top: 0;
}

.react-web--edwinsandbox__container .pt-10 {
  padding-top: 2.5rem;
}

.react-web--edwinsandbox__container .pt-14 {
  padding-top: 3.5rem;
}

.react-web--edwinsandbox__container .pt-16 {
  padding-top: 4rem;
}

.react-web--edwinsandbox__container .pt-2 {
  padding-top: .5rem;
}

.react-web--edwinsandbox__container .pt-2\.5 {
  padding-top: .625rem;
}

.react-web--edwinsandbox__container .pt-3 {
  padding-top: .75rem;
}

.react-web--edwinsandbox__container .pt-5 {
  padding-top: 1.25rem;
}

.react-web--edwinsandbox__container .pt-\[36px\] {
  padding-top: 36px;
}

.react-web--edwinsandbox__container .text-left {
  text-align: left;
}

.react-web--edwinsandbox__container .text-center {
  text-align: center;
}

.react-web--edwinsandbox__container .align-middle {
  vertical-align: middle;
}

.react-web--edwinsandbox__container .font-p22 {
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.react-web--edwinsandbox__container .\!text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.react-web--edwinsandbox__container .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.react-web--edwinsandbox__container .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.react-web--edwinsandbox__container .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.react-web--edwinsandbox__container .text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.react-web--edwinsandbox__container .text-\[18px\] {
  font-size: 18px;
}

.react-web--edwinsandbox__container .text-\[20px\] {
  font-size: 20px;
}

.react-web--edwinsandbox__container .text-\[22px\] {
  font-size: 22px;
}

.react-web--edwinsandbox__container .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.react-web--edwinsandbox__container .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.react-web--edwinsandbox__container .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.react-web--edwinsandbox__container .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.react-web--edwinsandbox__container .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.react-web--edwinsandbox__container .font-bold {
  font-weight: 700;
}

.react-web--edwinsandbox__container .font-extrabold {
  font-weight: 800;
}

.react-web--edwinsandbox__container .font-medium {
  font-weight: 500;
}

.react-web--edwinsandbox__container .font-normal {
  font-weight: 400;
}

.react-web--edwinsandbox__container .font-semibold {
  font-weight: 600;
}

.react-web--edwinsandbox__container .uppercase {
  text-transform: uppercase;
}

.react-web--edwinsandbox__container .leading-\[14px\] {
  line-height: 14px;
}

.react-web--edwinsandbox__container .leading-\[20px\] {
  line-height: 20px;
}

.react-web--edwinsandbox__container .leading-\[22px\] {
  line-height: 22px;
}

.react-web--edwinsandbox__container .leading-\[24px\] {
  line-height: 24px;
}

.react-web--edwinsandbox__container .leading-\[26px\] {
  line-height: 26px;
}

.react-web--edwinsandbox__container .leading-\[30px\] {
  line-height: 30px;
}

.react-web--edwinsandbox__container .tracking-tight {
  letter-spacing: -.025em;
}

.react-web--edwinsandbox__container .tracking-wider {
  letter-spacing: .05em;
}

.react-web--edwinsandbox__container .text-codGray {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-codGray\/60 {
  color: #19191999;
}

.react-web--edwinsandbox__container .text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-green {
  --tw-text-opacity: 1;
  color: rgb(124 145 207 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-green-dark {
  --tw-text-opacity: 1;
  color: rgb(42 57 90 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .underline {
  text-decoration-line: underline;
}

.react-web--edwinsandbox__container .\!opacity-100 {
  opacity: 1 !important;
}

.react-web--edwinsandbox__container .opacity-0 {
  opacity: 0;
}

.react-web--edwinsandbox__container .opacity-100 {
  opacity: 1;
}

.react-web--edwinsandbox__container .opacity-25 {
  opacity: .25;
}

.react-web--edwinsandbox__container .opacity-30 {
  opacity: .3;
}

.react-web--edwinsandbox__container .opacity-50 {
  opacity: .5;
}

.react-web--edwinsandbox__container .opacity-60 {
  opacity: .6;
}

.react-web--edwinsandbox__container .opacity-75 {
  opacity: .75;
}

.react-web--edwinsandbox__container .opacity-80 {
  opacity: .8;
}

.react-web--edwinsandbox__container .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--edwinsandbox__container .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--edwinsandbox__container .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--edwinsandbox__container .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--edwinsandbox__container .shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--edwinsandbox__container .\!outline {
  outline-style: solid !important;
}

.react-web--edwinsandbox__container .outline {
  outline-style: solid;
}

.react-web--edwinsandbox__container .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--edwinsandbox__container .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--edwinsandbox__container .backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--edwinsandbox__container .backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(.8);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--edwinsandbox__container .backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--edwinsandbox__container .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--edwinsandbox__container .transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--edwinsandbox__container .transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--edwinsandbox__container .transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--edwinsandbox__container .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--edwinsandbox__container .duration-200 {
  transition-duration: .2s;
}

.react-web--edwinsandbox__container .duration-300 {
  transition-duration: .3s;
}

.react-web--edwinsandbox__container .ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.react-web--edwinsandbox__container .ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.react-web--edwinsandbox__container .animate-delay-200 {
  animation-delay: .2s;
}

.react-web--edwinsandbox__container .animate-delay-300 {
  animation-delay: .3s;
}

.react-web--edwinsandbox__container .animate-delay-400 {
  animation-delay: .4s;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves ExtraLight.c1d23143.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves Light.e9f17eaa.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves Regular.8b41ef9c.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves Medium.0cb05482.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves DemiBold.5bcfd405.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: TTHoves;
  src: local(Poppins), url("TT Hoves Bold.4e3a0d16.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: P22Mackinac;
  src: local(Poppins), url("P22Mackinac-Book_13.59f11966.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: P22Mackinac;
  src: local(Poppins), url("P22Mackinac-Medium_6.96677939.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: P22Mackinac;
  src: local(Poppins), url("P22Mackinac-Bold_23.79dd5a2d.otf") format("opentype");
  font-weight: 700;
}

.ul-list li:before {
  content: "•";
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: -.875rem;
}

.ol-list {
  counter-reset: item;
}

.ol-list li {
  margin-left: 44px !important;
}

.ol-list li:before {
  content: counter(item);
  counter-increment: item;
  width: 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3rem;
  display: inline-block;
  position: absolute;
  left: -1.25rem;
}

.loader {
  width: 16px;
  height: 16px;
  position: relative;
}

.loader:before {
  width: 16px;
  height: 16px;
  text-align: center;
  content: "" "😀";
  font-size: 16px;
  animation: 1.2s steps(9, end) infinite forwards emojiLoader;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes emojiLoader {
  0% {
    content: "" "😀";
  }

  10% {
    content: "" "😅";
  }

  20% {
    content: "" "😊";
  }

  30% {
    content: "" "🤔";
  }

  40% {
    content: "" "😎";
  }

  50% {
    content: "" "🤓";
  }

  60% {
    content: "" "🥰";
  }

  70% {
    content: "" "😇";
  }

  80% {
    content: "" "😜";
  }

  90% {
    content: "" "🤯";
  }
}

.loader.second:before {
  content: "" "🙂";
  animation: 1s steps(9, end) infinite forwards emojiLoaderSecond;
}

@keyframes emojiLoaderSecond {
  0% {
    content: "" "🙂";
  }

  10% {
    content: "" "🙃";
  }

  20% {
    content: "" "😆";
  }

  30% {
    content: "" "😴";
  }

  40% {
    content: "" "😬";
  }

  50% {
    content: "" "😁";
  }

  60% {
    content: "" "😏";
  }

  70% {
    content: "" "🤪";
  }

  80% {
    content: "" "😮";
  }

  90% {
    content: "" "😋";
  }
}

.loader.third:before {
  content: "" "😇";
  animation: 1.4s steps(9, end) infinite forwards emojiLoaderThird;
}

@keyframes emojiLoaderThird {
  0% {
    content: "" "😇";
  }

  10% {
    content: "" "😜";
  }

  20% {
    content: "" "🥳";
  }

  30% {
    content: "" "😌";
  }

  40% {
    content: "" "🤠";
  }

  50% {
    content: "" "🤩";
  }

  60% {
    content: "" "🤤";
  }

  70% {
    content: "" "😱";
  }

  80% {
    content: "" "🤗";
  }

  90% {
    content: "" "😷";
  }
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected, .react-datepicker__day.react-datepicker__day--selected {
  background-color: #2a395a !important;
}

.react-web--edwinsandbox__container .first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.react-web--edwinsandbox__container .first\:pt-3:first-child {
  padding-top: .75rem;
}

.react-web--edwinsandbox__container .first\:pt-6:first-child {
  padding-top: 1.5rem;
}

.react-web--edwinsandbox__container .last\:mb-0:last-child {
  margin-bottom: 0;
}

.react-web--edwinsandbox__container .last\:mb-1:last-child {
  margin-bottom: .25rem;
}

.react-web--edwinsandbox__container .last\:mr-0:last-child {
  margin-right: 0;
}

.react-web--edwinsandbox__container .last\:mr-6:last-child {
  margin-right: 1.5rem;
}

.react-web--edwinsandbox__container .last\:border-0:last-child {
  border-width: 0;
}

.react-web--edwinsandbox__container .last\:pb-0:last-child {
  padding-bottom: 0;
}

.react-web--edwinsandbox__container .last\:pb-4:last-child {
  padding-bottom: 1rem;
}

.react-web--edwinsandbox__container .checked\:border-codGray:checked {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .checked\:border-green-dark:checked {
  --tw-border-opacity: 1;
  border-color: rgb(42 57 90 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .hover\:bg-green-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(42 57 90 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .hover\:opacity-90:hover {
  opacity: .9;
}

.react-web--edwinsandbox__container .focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.react-web--edwinsandbox__container .focus\:bg-green-dark:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(42 57 90 / var(--tw-bg-opacity));
}

.react-web--edwinsandbox__container .focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--edwinsandbox__container .focus\:\!shadow-none:focus {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.react-web--edwinsandbox__container .focus\:\!outline-none:focus {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.react-web--edwinsandbox__container .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.react-web--edwinsandbox__container .focus\:\!ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.react-web--edwinsandbox__container .focus\:\!ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.react-web--edwinsandbox__container .focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--edwinsandbox__container .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--edwinsandbox__container .focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.react-web--edwinsandbox__container .focus\:\!ring-codGray:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(25 25 25 / var(--tw-ring-opacity)) !important;
}

.react-web--edwinsandbox__container .focus\:ring-codGray:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(25 25 25 / var(--tw-ring-opacity));
}

.react-web--edwinsandbox__container .focus\:ring-green-dark:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(42 57 90 / var(--tw-ring-opacity));
}

.react-web--edwinsandbox__container .focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.react-web--edwinsandbox__container .focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.react-web--edwinsandbox__container .focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.react-web--edwinsandbox__container .disabled\:cursor-auto:disabled {
  cursor: auto;
}

.react-web--edwinsandbox__container .disabled\:opacity-80:disabled {
  opacity: .8;
}

@media (width >= 640px) {
  .react-web--edwinsandbox__container .sm\:inset-x-\[15\%\] {
    left: 15%;
    right: 15%;
  }

  .react-web--edwinsandbox__container .sm\:bottom-\[20\%\] {
    bottom: 20%;
  }

  .react-web--edwinsandbox__container .sm\:top-\[10\%\] {
    top: 10%;
  }

  .react-web--edwinsandbox__container .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .react-web--edwinsandbox__container .sm\:ml-5 {
    margin-left: 1.25rem;
  }
}

@media (width >= 768px) {
  .react-web--edwinsandbox__container .md\:mt-2 {
    margin-top: .5rem;
  }

  .react-web--edwinsandbox__container .md\:block {
    display: block;
  }

  .react-web--edwinsandbox__container .md\:max-h-\[60\%\] {
    max-height: 60%;
  }

  .react-web--edwinsandbox__container .md\:w-60 {
    width: 15rem;
  }

  .react-web--edwinsandbox__container .md\:max-w-none {
    max-width: none;
  }

  .react-web--edwinsandbox__container .md\:snap-none {
    scroll-snap-type: none;
  }

  .react-web--edwinsandbox__container .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .react-web--edwinsandbox__container .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .react-web--edwinsandbox__container .md\:font-medium {
    font-weight: 500;
  }

  .react-web--edwinsandbox__container .md\:hover\:bg-green-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(42 57 90 / var(--tw-bg-opacity));
  }

  .react-web--edwinsandbox__container .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (width >= 1024px) {
  .react-web--edwinsandbox__container .lg\:order-1 {
    order: 1;
  }

  .react-web--edwinsandbox__container .lg\:order-last {
    order: 9999;
  }

  .react-web--edwinsandbox__container .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .react-web--edwinsandbox__container .lg\:ml-0 {
    margin-left: 0;
  }

  .react-web--edwinsandbox__container .lg\:ml-11 {
    margin-left: 2.75rem;
  }

  .react-web--edwinsandbox__container .lg\:mt-0 {
    margin-top: 0;
  }

  .react-web--edwinsandbox__container .lg\:box-content {
    box-sizing: content-box;
  }

  .react-web--edwinsandbox__container .lg\:block {
    display: block;
  }

  .react-web--edwinsandbox__container .lg\:flex {
    display: flex;
  }

  .react-web--edwinsandbox__container .lg\:hidden {
    display: none;
  }

  .react-web--edwinsandbox__container .lg\:h-9 {
    height: 2.25rem;
  }

  .react-web--edwinsandbox__container .lg\:h-\[380px\] {
    height: 380px;
  }

  .react-web--edwinsandbox__container .lg\:h-\[410px\] {
    height: 410px;
  }

  .react-web--edwinsandbox__container .lg\:h-\[561px\] {
    height: 561px;
  }

  .react-web--edwinsandbox__container .lg\:max-h-none {
    max-height: none;
  }

  .react-web--edwinsandbox__container .lg\:w-1\/2 {
    width: 50%;
  }

  .react-web--edwinsandbox__container .lg\:w-9 {
    width: 2.25rem;
  }

  .react-web--edwinsandbox__container .lg\:w-96 {
    width: 24rem;
  }

  .react-web--edwinsandbox__container .lg\:w-\[602px\] {
    width: 602px;
  }

  .react-web--edwinsandbox__container .lg\:w-auto {
    width: auto;
  }

  .react-web--edwinsandbox__container .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .react-web--edwinsandbox__container .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .react-web--edwinsandbox__container .lg\:max-w-md {
    max-width: 28rem;
  }

  .react-web--edwinsandbox__container .lg\:max-w-none {
    max-width: none;
  }

  .react-web--edwinsandbox__container .lg\:max-w-xl {
    max-width: 36rem;
  }

  .react-web--edwinsandbox__container .lg\:flex-row {
    flex-direction: row;
  }

  .react-web--edwinsandbox__container .lg\:justify-start {
    justify-content: flex-start;
  }

  .react-web--edwinsandbox__container .lg\:gap-x-2 {
    column-gap: .5rem;
  }

  .react-web--edwinsandbox__container .lg\:self-end {
    align-self: flex-end;
  }

  .react-web--edwinsandbox__container .lg\:self-center {
    align-self: center;
  }

  .react-web--edwinsandbox__container .lg\:overflow-hidden {
    overflow: hidden;
  }

  .react-web--edwinsandbox__container .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .react-web--edwinsandbox__container .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .react-web--edwinsandbox__container .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .react-web--edwinsandbox__container .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .react-web--edwinsandbox__container .lg\:pl-16 {
    padding-left: 4rem;
  }

  .react-web--edwinsandbox__container .lg\:pr-1 {
    padding-right: .25rem;
  }

  .react-web--edwinsandbox__container .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .react-web--edwinsandbox__container .lg\:pr-8 {
    padding-right: 2rem;
  }

  .react-web--edwinsandbox__container .lg\:pt-0 {
    padding-top: 0;
  }

  .react-web--edwinsandbox__container .lg\:pt-3 {
    padding-top: .75rem;
  }

  .react-web--edwinsandbox__container .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .react-web--edwinsandbox__container .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .react-web--edwinsandbox__container .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .react-web--edwinsandbox__container .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .react-web--edwinsandbox__container .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .react-web--edwinsandbox__container .lg\:tracking-widest {
    letter-spacing: .1em;
  }

  .react-web--edwinsandbox__container .lg\:last\:mr-5:last-child {
    margin-right: 1.25rem;
  }
}

@media (width >= 1280px) {
  .react-web--edwinsandbox__container .xl\:w-\[360px\] {
    width: 360px;
  }

  .react-web--edwinsandbox__container .xl\:pr-4 {
    padding-right: 1rem;
  }
}

