@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves DemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Book_13.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Medium_6.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Bold_23.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto';
  src: local('Poppins'), url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

html,
body,
#root,
.react-web-app--edwinsandbox__container {
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.truncate-fourth-line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clip-svg {
  clip-path: url(#myClip);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
