@import './tailwind-normalize.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves DemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Book_13.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Medium_6.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Bold_23.otf') format('opentype');
  font-weight: 700;
}

.ul-list li:before {
  content: "•";
  @apply absolute -top-[3px] -left-3.5 inline-block text-base;
}

.ol-list {
  counter-reset: item
}

.ol-list li {
  margin-left: 44px !important;
}

.ol-list li:before {
  content: counter(item);
  counter-increment: item;
  @apply absolute -left-5 inline-block w-5 text-base leading-[1.3rem] font-medium;
}

$emojis1: "😀", "😅", "😊", "🤔", "😎", "🤓", "🥰", "😇", "😜", "🤯";
$emojis2: "🙂", "🙃", "😆", "😴", "😬", "😁", "😏", "🤪", "😮", "😋";
$emojis3: "😇", "😜", "🥳", "😌", "🤠", "🤩", "🤤", "😱", "🤗", "😷";

// Mixin to generate the proper animation based off the emoji list provided
@mixin emojiLoader($emoji-list, $loader-name: emojiLoader, $step-duration: .12s, $prefix: '') {
  /*
  @param $emoji-list: This is a list of the emojis you want in the animation
  @param $loader-name: Define a different name for the loader animation
  @param $step-duration: Define how long each emoji is displayed
  @param $prefix: This allows you to add a prefix such as "Loading… " to have that text show next to the changing emoji - you might want to adjust the .loader class's width, height and font-size.
  */

  $emoji-count: length($emoji-list); // Get the number of emojis
  $steps: $emoji-count - 1;
  content: $prefix nth($emoji-list, 1); // Default is the first emoji in the list (fallback for Safari)
  animation: #{$loader-name} #{$step-duration * $emoji-count} steps(#{$steps}) infinite forwards 0s;

  @keyframes #{$loader-name} { // Sass is smart and compiles this outside of our declaration - yay!
    @for $e from 1 through $emoji-count { // Loop through all emojis
      $this-percentage: 100 / $emoji-count * ($e - 1);
      #{$this-percentage}% { content: $prefix nth($emoji-list, $e); }
    }
  }
}

.loader {
  position: relative;
  width: 16px;
  height: 16px;

  &::before {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    font-size: 16px;
    text-align: center;
    transform: translate(-50%, -50%);
    @include emojiLoader($emojis1);
  }

  &.second {
    &::before {
      @include emojiLoader($emojis2, 'emojiLoaderSecond', 0.1s);
    }
  }

  &.third {
    &::before {
      @include emojiLoader($emojis3, 'emojiLoaderThird', 0.14s);
    }
  }
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected,
.react-datepicker__day.react-datepicker__day--selected{
  background-color: #2A395A !important;
}